
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ProductSlider.css */
*{
  font-family: "Roboto", sans-serif;

}
.scroll-container {
    /* display: flex; */
    overflow-x: auto;
    white-space: nowrap;
    transition: transform 0.2s ease-in-out; 
    scroll-behavior: smooth;
  }
  
  .scroll-item {
    margin-right: 20px; 
    transition: transform 0.3s ease-in-out; 
  }

  .swiper-pagination{
    position: unset !important;
  }
  

  .scrollbar-hide::-webkit-scrollbar,
  .overflow-x-auto::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .h1{
    @apply text-black  font-normal text-5xl
  }

  .h2{
    @apply text-black   font-bold text-4xl
  }
   
  .h3{
    @apply text-black  font-bold text-2xl
  }
  .h4{
    @apply text-black font-bold text-xl
  }
 
  .h5{
    @apply text-black font-bold  text-lg
  } 
  .h6{
    @apply text-black font-bold   text-sm
  }


  .swiper-pagination{
   position: unset !important;
  }

  .swiper-pagination-bullet-active{
    background: #000 !important;
  }