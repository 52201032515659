body{
    overflow-x: hidden;
    background-color: white !important;
}

.opt-container{

}
.opt-container input{
    border: 1px solid  gray !important;

}

.rounded-full{
    /* border-radius: 20px !important; */
}