/* HorizontalScroll.css */
.scroll-container {
    width: 100%;
    white-space: nowrap;
  }
  
  .scroll-item {
    min-width: 200px; /* Adjust as needed */
    box-sizing: border-box;
  }

  .productOverflow::-webkit-scrollbar {
    display: none;
}